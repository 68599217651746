import { computed, useAttrs } from 'vue';

type Attrs = {
  [key: string]: unknown;
  class?: string;
  style?: CSSStyleDeclaration;
};

export default function useStyleClassExtractedAttrs() {
  const attrs: Attrs = useAttrs();
  const classAttr = computed(() => attrs.class);
  const styleAttr = computed(() => attrs.style);
  const otherAttrs = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { class: _, style: __, ...other } = attrs;
    return other;
  });

  return {
    classAttr,
    styleAttr,
    otherAttrs,
  };
}
