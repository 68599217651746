<script setup lang="ts">
import { computed, ref } from 'vue';

import type { OnboardingIssue } from '@apis/generated';
import type { StepProps } from '../steps';

import { IssueTypeEnumEnum } from '@apis/generated';

import ActivationTemplate from 'loginApp/components/activation-template.vue';
import laboratorImage from 'loginApp/images/laborator.svg';
import useOnboardingStore from 'loginApp/store/modules/onboarding.ts';
import SelectOne from 'sharedApp/components/activation/inputs/select-one.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTextTrim from 'sharedApp/components/base/utils/kog-text-trim.vue';
import RadioGroupWithInput from 'sharedApp/components/radio-group-with-input/radio-group-with-input.vue';
import { getDayFromXMonthsAgo } from 'sharedApp/utils/time-utils.js';

const props = defineProps<StepProps>();
const emit = defineEmits(['next']);

const store = useOnboardingStore();

type WrongClassesReason = {
  isValid: boolean;
  radioValue: 'missing-need-help' | 'missing-add-self' | 'other';
  customValue: string;
};

const isCorrectClasses = ref<string | null>(null);
const wrongClassesReason = ref<WrongClassesReason | null>(null);
const isFetchingMore = ref(false);

const singularClass = computed(() => store.teacherClasses.length === 1);

const isCorrectClassesOptions = computed(() => [
  { label: singularClass.value ? 'Yes, this is correct' : 'Yes, these are correct', value: 'yes' },
  { label: 'No, something is wrong', value: 'no' },
]);

const wrongClassesReasonOptions = [
  { text: 'Missing classes – I want help adding them', value: 'missing-need-help' },
  { text: 'Missing classes – I can add them myself', value: 'missing-add-self' },
  {
    text: 'Other',
    value: 'other',
  },
];

const isSubmittable = computed(
  () =>
    isCorrectClasses.value === 'yes' ||
    (isCorrectClasses.value === 'no' && !!wrongClassesReason.value?.isValid),
);

const recentlyArchivedClasses = computed(() => {
  const sixMonthsAgo = getDayFromXMonthsAgo(6);
  return store.expiredClasses.filter(expiredClass => {
    return new Date(expiredClass.end_date) > sixMonthsAgo;
  });
});

const getClassSetupIssue = () => {
  const classSetupIssueLookup: Record<string, OnboardingIssue> = {
    'missing-need-help': {
      issue_type: IssueTypeEnumEnum.MISSING_CLASSES,
      needs_help: true,
    },
    'missing-add-self': {
      issue_type: IssueTypeEnumEnum.MISSING_CLASSES,
      needs_help: false,
    },
    other: {
      issue_type: 'other',
      needs_help: true,
      extra_reason: wrongClassesReason.value?.customValue,
    },
  };

  return classSetupIssueLookup[wrongClassesReason.value!.radioValue];
};

const handleFetchMore = () => {
  isFetchingMore.value = true;
  store.fetchTeacherClasses(store.totalTeacherClasses);
};

const handleWrongClassesReasonEnter = () => {
  window.scrollTo({
    top: document.body.scrollHeight || document.documentElement.scrollHeight,
    behavior: 'smooth',
  });
};

const handleContinue = () => {
  if (isSubmittable.value && isCorrectClasses.value === 'no') {
    store.classSetupIssue = getClassSetupIssue();
  }
  emit('next');
};

const buttonLabel = computed(() => {
  const prefix = isCorrectClasses.value === 'no' ? 'Submit and ' : '';
  const action = props.isLastStep ? 'Go to dashboard' : 'Continue';

  if (prefix) {
    return `${prefix}${action.toLowerCase()}`;
  }
  return action;
});
</script>

<template>
  <activation-template
    title="Review your assigned classes"
    subtitle="The following classes are currently assigned to you."
    :progress="progress"
  >
    <template #action>
      <p class="font-weight-bold">Note: You can always join or leave classes later.</p>
      <p class="font-weight-bold margin-top-l"> {{ store.totalTeacherClasses }} classes</p>
      <div
        v-for="subjectClass in store.teacherClasses"
        :key="subjectClass.id"
        class="ClassCard padd-s margin-bottom-xs"
      >
        <kog-text-trim
          class="heading-xs"
          :text="subjectClass.name"
          :max-lines="2"
        />
        <p class="text-small margin-bottom-0">Course: {{ subjectClass.subject.display_name }}</p>
      </div>
      <span
        v-if="recentlyArchivedClasses.length > 0"
        v-tooltip="{
          theme: 'kog-tooltip',
          content: recentlyArchivedClasses.map(c => c.name).join('<br>'),
          placement: 'bottom',
        }"
        class="text-regular text-underline"
      >
        {{ recentlyArchivedClasses.length }} classes recently archived
      </span>
      <kog-button
        v-if="store.totalTeacherClasses > store.teacherClasses.length"
        class="width-full margin-top-xs margin-bottom-l"
        :label="`Show all ${store.totalTeacherClasses} classes`"
        :is-loading="isFetchingMore"
        @click="handleFetchMore"
      />
      <p class="font-weight-bold margin-top-xl">{{
        singularClass ? 'Is this class correct?' : 'Are these the classes you expected?'
      }}</p>
      <select-one
        v-model="isCorrectClasses"
        :options="isCorrectClassesOptions"
        description="Check if the classes assigned to you are correct."
        :include-other="false"
      />
      <transition
        name="fade"
        @enter="handleWrongClassesReasonEnter"
      >
        <div
          v-if="isCorrectClasses === 'no'"
          class="WrongClassesReasonContainer padd-m margin-top-xs"
        >
          <p class="font-weight-bold margin-bottom-0">What's wrong?</p>
          <p>
            Our support team will be notified to ensure you get access to the correct classes.
          </p>
          <radio-group-with-input
            v-model="wrongClassesReason"
            :options="wrongClassesReasonOptions"
            input-always-visible
          />
        </div>
      </transition>
      <kog-button
        class="width-full margin-top-m margin-bottom-l"
        button-style="accent"
        :label="buttonLabel"
        :disabled="!isSubmittable || isLoading"
        :is-loading="isLoading"
        @click="handleContinue"
      />
    </template>
    <template #extra>
      <div class="flexContainer flexContainer-column flexContainer-alignCenter">
        <img
          :src="laboratorImage"
          alt=""
          width="540"
        />
        <div class="HelperTipContainer shadow-s-030 border-radius-8 padd-l kogPlatformWhite-bg">
          <p class="heading-s margin-bottom-s">Helper tip!</p>
          <p>
            Having the right classes ensures that you can get started with Kognity in the best
            possible way.
          </p>
          <p>
            If the classes are not what you expect, our support team will help you get the right
            classes in place as soon as possible.
          </p>
        </div>
      </div>
    </template>
  </activation-template>
</template>

<style scoped>
.ClassCard {
  border: 1px solid var(--kog-component-outline-border-color);
  border-radius: 8px;
}

.WrongClassesReasonContainer {
  background-color: var(--kog-colors-light-300);
  border: 1px solid var(--kog-colors-yellow-700);
  border-radius: 8px;
}

.HelperTipContainer {
  width: 360px;
  margin-top: -135px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
