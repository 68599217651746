<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { AccountsService } from '@apis/generated';

import ActivationTemplate from 'loginApp/components/activation-template.vue';
import InteractiveLearningPlatformVisual from 'loginApp/components/activation/interactive-learning-platform-visual.vue';
import KogNewNavbar from 'sharedApp/components/core/kog-new-navbar.vue';
import KognityLogoUrl from 'sharedApp/img/kognity-logo-text-green.svg';
import posthog from 'sharedApp/libs/posthog.ts';

import ResendInvitation from './components/resend-invitation.vue';

const route = useRoute();
const userId = +route.params.userId;
const invitationToken = route.params.token as string;

const userEmail = ref<string | null>(null);
const isInvalidToken = ref<boolean>(false);

const redirectToSSO = (url: string) => {
  const newUrl = new URL(url, window.location.href);
  window.location.href = newUrl.toString();
};

const fetchUser = async () => {
  try {
    const invitationResponse = await AccountsService.accountsUsersInvitationsRetrieve({
      userId,
      token: invitationToken,
    });
    const instantRedirectUrl = invitationResponse.authentication_providers.instant_redirect_url;
    if (instantRedirectUrl) {
      redirectToSSO(instantRedirectUrl);
    }
    userEmail.value = invitationResponse.email;
  } catch {
    isInvalidToken.value = true;
  }
};

const initPosthog = () => {
  posthog.init(
    userId,
    {},
    {
      disable_session_recording: true,
    },
  );
  posthog.startSessionRecording();
};

onMounted(() => {
  fetchUser();
  initPosthog();
});

const isLoading = computed(() => userEmail.value === null && !isInvalidToken.value);

const title = computed(() => {
  if (isInvalidToken.value) {
    return 'This activation link has expired';
  }
  return 'Sign in to activate your account';
});

const subtitle = computed(() => {
  if (isLoading.value) {
    return 'Loading...';
  }
  if (isInvalidToken.value) {
    return '';
  }
  return `Your account email: ${userEmail.value}`;
});
</script>

<template>
  <div class="AcceptInvitationPage--container">
    <kog-new-navbar>
      <template #leftCornerSlot>
        <img
          :src="KognityLogoUrl"
          alt="Kognity"
        />
      </template>
    </kog-new-navbar>
    <div class="AcceptInvitationPage--content">
      <activation-template
        :title="title"
        :subtitle="subtitle"
      >
        <template #action>
          <div v-if="!isLoading && !isInvalidToken">
            <router-view />
          </div>
          <div v-else-if="isInvalidToken">
            <resend-invitation />
          </div>
        </template>
        <template #extra>
          <interactive-learning-platform-visual />
        </template>
      </activation-template>
    </div>
  </div>
</template>

<style scoped>
.AcceptInvitationPage--content {
  overflow: hidden;
  padding-top: var(--full-page-navbar-height);
}
</style>
