<script setup lang="ts">
import { useRouter } from 'vue-router';

import SsoLogin from 'loginApp/pages/login/components/sso-login.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

const router = useRouter();
const ssoProviders = ['google', 'managebac-global', 'managebac-china'];

const loginSSO = (provider: { url: string }) => {
  const { url } = provider;

  const newUrl = new URL(url, window.location.href);
  window.location.href = newUrl.toString();
};
</script>

<template>
  <p class="margin-top-xl"> Sign in with: </p>
  <sso-login
    :provider-names="ssoProviders"
    theme="simple"
    @click="loginSSO"
  />
  <p class="margin-top-l"> Or set a password: </p>
  <kog-button
    class="width-full"
    label="Set a password"
    @click="router.push({ name: 'set-password' })"
  />
</template>
