import axios from 'axios';

const invitationBaseUrl = (userId: number, token: string) =>
  `/api/accounts/users/${userId}/invitations/${token}`;

const acceptInvitation = async (userId: number, invitationToken: string, password: string) => {
  await axios.post(`${invitationBaseUrl(userId, invitationToken)}/set-password`, {
    password,
  });
};

const resendInvitation = async (email: string) => {
  await axios.post('/api/accounts/users/invitations/resend', { email });
};

export default {
  acceptInvitation,
  resendInvitation,
};
