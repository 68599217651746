<script setup lang="ts">
import { computed, ref } from 'vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import SetPasswordInput from 'sharedApp/components/set-password-input/set-password-input.vue';

type SetPasswordProps = {
  isLoading?: boolean;
};
defineProps<SetPasswordProps>();

const emit = defineEmits<{
  submit: [password: string];
}>();

const password = ref('');

const canSubmit = computed(() => password.value);
const submit = () => {
  if (canSubmit.value) {
    emit('submit', password.value);
  }
};
</script>

<template>
  <div>
    <set-password-input @validated-input="value => (password = value)" />
    <div class="flexContainer margin-top-l">
      <kog-button
        class="width-full"
        button-style="accent"
        label="Set password and sign in"
        full-width
        :is-loading="isLoading"
        :disabled="!canSubmit || isLoading"
        @click="submit"
      />
    </div>
  </div>
</template>
