<template>
  <generic-warning-box>
    <p class="text-bold"> The activation link is no longer available. </p>
    <p>
      The activation link can only be used once. Either it has already been used to login for the
      first time or it has expired. Log in below to access your account or
      <router-link to="password-reset"> reset your password. </router-link>
    </p>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  name: 'InvalidTokenWarningBox',
  components: {
    GenericWarningBox,
  },
};
</script>
