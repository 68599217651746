<script setup>
import { computed } from 'vue';

import InvalidTokenWarningBox from './login-warnings/invalid-token-warning-box.vue';
import SSOUnknownErrorWarningBox from './login-warnings/sso-unknown-error-warning-box.vue';
import UnauthenticatedWarningBox from './login-warnings/unauthenticated-warning-box.vue';
import WarningBox from './login-warnings/warning-box.vue';

const messages = {
  noSchool: `This email account is not yet linked to your school. To access your Kognity account,
  please contact your school's Kognity administrator to add you.`,
  noUser: `We were unable to log you in. There is no account on Kognity with this email.
  Please contact your school's Kognity administrator to create an account for you.`,
  timeout: 'The login request timed out, please give it another try.',
  pleaseLogin: 'Please log in to access Kognity.',
};

const warnings = {
  invalid_token: {
    component: InvalidTokenWarningBox,
  },
  no_school: {
    component: WarningBox,
    props: { message: messages.noSchool },
  },
  sso_no_user: {
    component: WarningBox,
    props: { message: messages.noUser },
  },
  sso_unknown_error: {
    component: SSOUnknownErrorWarningBox,
  },
  sso_timeout_error: {
    component: WarningBox,
    props: { message: messages.timeout },
  },
  unauthenticated_user: {
    component: UnauthenticatedWarningBox,
  },
  demo_user_warning: {
    component: WarningBox,
    props: { message: messages.pleaseLogin },
  },
};

const props = defineProps({
  warningCode: {
    type: String,
    required: true,
  },
});

const warningBoxComponent = computed(() => {
  return warnings[props.warningCode].component;
});
const warningBoxProps = computed(() => {
  return warnings[props.warningCode].props;
});
</script>

<template>
  <div>
    <warning-box-component
      :is="warningBoxComponent"
      v-bind="warningBoxProps"
    />
  </div>
</template>
