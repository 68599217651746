<script setup lang="ts">
import { computed, ref } from 'vue';

import type { StepProps } from '../steps';

import { OnboardingVersionEnumEnum } from '@apis/generated';

import ActivationTemplate from 'loginApp/components/activation-template.vue';
import otherImage from 'loginApp/images/other.svg';
import studentImage from 'loginApp/images/student.svg';
import teacherImage from 'loginApp/images/teacher.svg';
import useOnboardingStore from 'loginApp/store/modules/onboarding.ts';
import SelectOne from 'sharedApp/components/activation/inputs/select-one.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

defineProps<StepProps>();
const emit = defineEmits(['next']);

const store = useOnboardingStore();
const selectedRole = ref<string | null>(null);

const choices = [
  { label: 'Student', value: 'student' },
  { label: 'Teacher', value: 'teacher' },
];

const isSubmittable = computed(() => !!selectedRole.value);

const handleContinue = () => {
  if (selectedRole.value) {
    store.userRole = selectedRole.value;
    emit('next');
  }
};

const roleImages: Record<string, string> = {
  teacher: teacherImage,
  student: studentImage,
  other: otherImage,
};

const selectedRoleCategory = computed(() => {
  if (selectedRole.value === null) {
    return null;
  }
  if (selectedRole.value in roleImages) {
    return selectedRole.value;
  }
  return 'other';
});

const imageDeltaRotation = 360 / Object.keys(roleImages).length;

const title =
  store.onboardingVersion === OnboardingVersionEnumEnum.ONBOARDING
    ? 'Welcome to Kognity!'
    : 'Welcome back to Kognity!';
const subtitle =
  store.onboardingVersion === OnboardingVersionEnumEnum.ONBOARDING
    ? "To get you started in the best possible way, let's begin with a quick account setup. This will only take a minute."
    : "We're excited to see you again. Help us tailor your experience for the academic year. This will only take a minute.";
const roleQuestion =
  store.onboardingVersion === OnboardingVersionEnumEnum.ONBOARDING
    ? 'What is your role?'
    : 'What is your role this year?';
</script>

<template>
  <activation-template
    :title="title"
    :subtitle="subtitle"
    :progress="progress"
  >
    <template #action>
      <p class="margin-top-xl text-bold"> {{ roleQuestion }} </p>
      <select-one
        v-model="selectedRole"
        description="Select your role"
        :options="choices"
        include-other
      />
      <kog-button
        class="width-full margin-top-m margin-bottom-l"
        button-style="accent"
        :label="isLastStep ? 'Go to dashboard' : 'Continue'"
        :disabled="!isSubmittable || isLoading"
        :is-loading="isLoading"
        @click="handleContinue"
      />
    </template>
    <template #extra>
      <div class="ImageContainer">
        <img
          v-for="(role, index) in Object.keys(roleImages)"
          :key="role"
          :src="roleImages[role]"
          :alt="role"
          :class="{
            [`Image`]: true,
            'Image--center': selectedRoleCategory === role,
            'Image--hidden': selectedRoleCategory && selectedRoleCategory !== role,
          }"
          :style="{ '--rotation': index * imageDeltaRotation + 'deg' }"
        />
      </div>
    </template>
  </activation-template>
</template>

<style scoped>
/* stylelint-disable suitcss/custom-property-no-outside-root */
.ImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.Image {
  --scale: 0.7;
  --distance: 50%;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotate(var(--rotation)) translate(var(--distance)) rotate(calc(var(--rotation) * -1))
    scale(var(--scale));

  width: 35%;
  margin: auto;

  transition: all 0.5s ease;
}

.Image--center {
  --distance: 0;
  --scale: 1.5;
}

.Image--hidden {
  --scale: 0;
}
/* stylelint-enable suitcss/custom-property-no-outside-root */
</style>
