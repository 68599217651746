<template>
  <generic-warning-box>
    We were unable to log you in. Please try again, if the issue continues contact us at
    <a href="mailto:support@kognity.com">support@kognity.com</a>.
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  name: 'SSOUnknownErrorWarningBox',
  components: {
    GenericWarningBox,
  },
};
</script>
