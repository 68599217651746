<script setup lang="ts">
import { computed, ref } from 'vue';

import type { StepProps } from 'loginApp/pages/onboarding/steps.ts';

import { OnboardingVersionEnumEnum } from '@apis/generated';

import ActivationTemplate from 'loginApp/components/activation-template.vue';
import EndorsementCard from 'loginApp/components/activation/endorsement-card.vue';
import useOnboardingStore from 'loginApp/store/modules/onboarding.ts';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import CheckboxGroup from 'sharedApp/components/checkbox-group/checkbox-group.vue';
import arrowVector from 'sharedApp/img/activation/vector-arrow.svg';

const PRIMARY_RESOURCE_INTEREST_SLUG = 'primary-resource';

const ENDORSEMENT_INFO = {
  quote:
    'I was pleasantly surprised, as students at the school can be quite critical when presented with new things and they are not very gullible. But in the case of Kognity, there was unanimous approval. They find it very useful. It is a brilliant replacement to the textbook and the course material is accessible anywhere at anytime.',
  author: 'Dr. Babus Das',
  authorRole: 'Head of Science',
};

defineProps<StepProps>();
const emit = defineEmits(['next']);

const store = useOnboardingStore();
const selectedInterests = ref<string[]>([]);
const freeTextInterest = ref<string>('');
const showOtherInput = ref<boolean>(false);
const primaryResource = ref<string>('');

const primaryResourceIntentionOptions = [
  { value: 'yes', text: 'Yes' },
  { value: 'no', text: 'No' },
];

const isSubmittable = computed(
  () =>
    (selectedInterests.value.length > 0 || freeTextInterest.value !== '') &&
    primaryResource.value !== '',
);

const interestOptions = computed(() =>
  store.availableInterests.map(interest => ({
    value: interest.slug,
    label: interest.title,
  })),
);

const clearOtherInput = () => {
  if (!showOtherInput.value) {
    freeTextInterest.value = '';
  }
};

const handleContinue = () => {
  if (isSubmittable.value) {
    store.userInterests = selectedInterests.value;
    if (primaryResource.value === 'yes') {
      store.userInterests.push(PRIMARY_RESOURCE_INTEREST_SLUG);
    }
    store.freeTextInterests = freeTextInterest.value;
    emit('next');
  }
};

const question =
  store.onboardingVersion === OnboardingVersionEnumEnum.ONBOARDING
    ? 'What are you most interested in using Kognity for?'
    : 'What are you most interested in using Kognity for this year?';
</script>

<template>
  <activation-template
    title="Your learning resource needs"
    subtitle="Kognity supports your everyday teaching. By understanding your resource needs, we can guide you through the product and make sure it works for you."
    :loading="store.availableInterests.length === 0"
    :progress="progress"
  >
    <template #action>
      <p class="margin-top-xl text-bold"> {{ question }} </p>
      <checkbox-group
        v-model="selectedInterests"
        :options="interestOptions"
        label="Select all that apply"
      />
      <kog-checkbox
        v-model:is-checked="showOtherInput"
        class="margin-top-xxs"
        label="Not sure / Other"
        @toggled="clearOtherInput"
      />
      <kog-input
        v-model:value="freeTextInterest"
        :class="[
          {
            'SelectInterests-otherInputHidden': !showOtherInput,
          },
        ]"
        :aria-hidden="!showOtherInput"
        class="SelectInterests-otherInput margin-top-xxs margin-bottom-xxs margin-left-xl"
        label="Other interests"
        :is-label-hidden="true"
        placeholder="Please describe"
      />
      <label
        class="heading-xxs margin-bottom-xxs"
        for="primary-resource-radio"
      >
        Do you intend to use Kognity as your primary resource/textbook?
      </label>
      <kog-radio-group
        id="primary-resource-radio"
        v-model:selected-value="primaryResource"
        :options="primaryResourceIntentionOptions"
      />
      <kog-button
        class="width-full margin-top-l margin-bottom-l"
        button-style="accent"
        :label="isLastStep ? 'Start exploring Kognity' : 'Continue'"
        :disabled="!isSubmittable || isLoading"
        :is-loading="isLoading"
        @click="handleContinue"
      />
    </template>
    <template #extra>
      <div class="height-full flexContainer flexContainer-alignCenter flexContainer-justifyCenter">
        <endorsement-card
          :quote="ENDORSEMENT_INFO.quote"
          :author="ENDORSEMENT_INFO.author"
          :author-role="ENDORSEMENT_INFO.authorRole"
        />
        <img
          :src="arrowVector"
          alt=""
          class="margin-bottom-xl margin-left-s"
        />
      </div>
    </template>
  </activation-template>
</template>

<style scoped>
.SelectInterests-otherInput {
  transition: opacity 500ms ease;
}

.SelectInterests-otherInputHidden {
  visibility: hidden;
  opacity: 0;
}
</style>
