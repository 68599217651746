<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import userInvitationApi from 'loginApp/api/user-invitation.ts';
import SetPassword from 'loginApp/components/set-password.vue';

const route = useRoute();
const userId = +route.params.userId;
const token = route.params.token as string;

const isSubmitting = ref(false);
const hasError = ref(false);

const onPasswordSubmitted = async (password: string) => {
  hasError.value = false;
  isSubmitting.value = true;
  try {
    await userInvitationApi.acceptInvitation(userId, token, password);
    window.location.href = '/';
  } catch {
    isSubmitting.value = false;
    hasError.value = true;
  }
};
</script>

<template>
  <div>
    <router-link :to="{ name: 'sso-container', params: { userId, token } }">
      <i class="fas fa-chevron-left"></i> Back to other options
    </router-link>
    <set-password
      class="margin-top-s"
      :is-loading="isSubmitting"
      @submit="onPasswordSubmitted"
    />
    <p
      v-if="hasError"
      class="error margin-top-s"
    >
      Could not set password. Please try again later.
    </p>
  </div>
</template>
