<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import userInvitationApi from 'loginApp/api/user-invitation.ts';
import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';

const isSendingInvitation = ref(false);
const hasSentInvitation = ref(false);
const email = ref('');
const hasError = ref(false);

const sendInvitation = async () => {
  isSendingInvitation.value = true;
  hasError.value = false;
  try {
    await userInvitationApi.resendInvitation(email.value);
    hasSentInvitation.value = true;
  } catch {
    hasError.value = true;
  } finally {
    isSendingInvitation.value = false;
  }
};

const isValidEmail = computed(() => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email.value);
});

const resendInvitationEmail = () => {
  if (isValidEmail.value) {
    sendInvitation();
  }
};

const router = useRouter();

const backToResendInvitation = () => {
  hasSentInvitation.value = false;
};

const goToLogin = () => {
  router.push('/');
};
</script>

<template>
  <div
    v-if="!hasSentInvitation"
    mode="error"
  >
    <form
      class="ResendInvitationForm margin-top-m"
      @submit.prevent="resendInvitationEmail"
    >
      <kog-input
        v-model:value="email"
        placeholder="Enter your email"
        label="To get a new link, enter your email adress"
      />
      <kog-button
        class="width-full margin-top-s"
        button-style="accent"
        label="Resend invitation"
        type="submit"
        :is-loading="isSendingInvitation"
        :disabled="!isValidEmail || isSendingInvitation"
      />
      <p
        v-if="hasError"
        class="error margin-top-s"
      >
        Could not send invitation. Please try again later.
      </p>
    </form>
  </div>
  <kog-alert
    v-else
    class="margin-top-m"
    mode="warning"
    :title="`A new invitation link has been sent to ${email}`"
  >
    <p> Go to your inbox and click on the link in the new invitation. </p>
    <p> If you cannot find it, look in your email's social, spam or campaign folder. </p>
    <kog-button
      label="Resend invitation"
      size="small"
      @click="backToResendInvitation"
    />
  </kog-alert>

  <div class="margin-top-xxxl">
    <kog-button
      class="width-full margin-top-s"
      label="Log in with Google"
      @click="goToLogin"
    />
  </div>
</template>
