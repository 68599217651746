import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_URLS = {
  login: '/api/accounts/login/',
  'password-reset': '/api/accounts/password_reset/',
  'authentication-providers': '/api/authentication/providers/',
};

const login = (email, password, nextUrl) => {
  const url = API_URLS.login;
  const data = {
    email,
    password,
    next_url: nextUrl,
  };
  return axios.post(url, data);
};

const resetPassword = email => {
  const data = {
    email,
  };
  return axios.post(API_URLS['password-reset'], data);
};

const getAuthenticationProviders = async email => {
  const url = API_URLS['authentication-providers'];
  const data = {
    email,
  };
  const response = await axios.put(url, data);
  return response.data;
};

export { login, resetPassword, getAuthenticationProviders };
