function isUndefinedOrNull(value) {
  return typeof value === 'undefined' || value === null;
}

function isZeroToHundred(value) {
  return value >= 0 && value <= 100;
}

function isZeroToHundredOrUndefinedOrNull(value) {
  return isUndefinedOrNull(value) || isZeroToHundred(value);
}

export { isUndefinedOrNull, isZeroToHundred, isZeroToHundredOrUndefinedOrNull };
