<script setup>
import { computed, ref } from 'vue';

import SsoButton from 'loginApp/pages/login/components/sso-button.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import googleLogo from 'sharedApp/img/google-signin-logo.png';
import managebacLogo from 'sharedApp/img/managebac-signin-logo.png';

import { defaultTheme, themes } from './shared.js';

const PROVIDERS_CONFIG = {
  google: {
    label: 'Google',
    url: '/accounts/login/google/',
    icon: googleLogo,
  },
  'managebac-global': {
    label: 'Managebac',
    url: '/accounts/login/managebac/?region=global',
    icon: managebacLogo,
  },
  'managebac-china': {
    label: 'Managebac China',
    url: '/accounts/login/managebac/?region=china',
    icon: managebacLogo,
  },
};

const props = defineProps({
  providerNames: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: defaultTheme,
    validator: value => themes.includes(value),
  },
});

const emit = defineEmits(['click']);

const loadingProvider = ref(null);

const loginSSO = provider => {
  loadingProvider.value = provider.label;
  emit('click', provider);
};

const isDisabled = computed(() => Boolean(props.disabled || loadingProvider.value));
const providersToShow = computed(() => {
  return props.providerNames.map(provider => PROVIDERS_CONFIG[provider]);
});

window.addEventListener('pageshow', event => {
  if (event.persisted) {
    loadingProvider.value = null;
  }
});
</script>

<template>
  <kog-item-list
    role="list"
    :is-handling-tab-index="true"
  >
    <div
      v-for="provider in providersToShow"
      :key="provider.label"
      role="listitem"
      class="margin-bottom-m"
    >
      <sso-button
        :icon="provider.icon"
        :label="provider.label"
        :disabled="isDisabled"
        :is-loading="loadingProvider === provider.label"
        :theme="theme"
        @click="loginSSO(provider)"
      />
    </div>
  </kog-item-list>
</template>
