<template>
  <div class="flexContainer flexContainer-center padd-top-xxl">
    <div class="OriginUnreachable z-index-1">
      <div class="OriginUnreachableCard padd-top-xl padd-left-xxl padd-right-xxl padd-bottom-xl">
        <h1 class="heading-s margin-bottom-l"> ⚠️ Kognity is currently unreachable ⚠️ </h1>
        <p>
          <b>The Kognity platform can't be reached by the edge servers.</b>
        </p>
        <p class="margin-top-l"> Hi there, </p>
        <p>
          The technology that powers Kognity is currently experiencing difficulties. The Kognity
          team is on it and apologizes for any immediate problems this may cause.
        </p>
        <p>
          If this problem persists, please reach out to our support team via the chat-bubble on
          Kognity's
          <a
            href="https://kognity.com/"
            target="_blank"
          >
            main website.
          </a>
        </p>
        <p class="margin-top-l">
          <b>Hold on tight, and please try again in a few minutes!</b>
        </p>
        <p class="margin-top-xxxl"> Error details follow: </p>
        <div>
          <!-- Tag is required by CloudFlare and will be replaced with debug info -->
          ::CLOUDFLARE_ERROR_500S_BOX::
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.OriginUnreachable {
  max-width: 600px;
}

.OriginUnreachableCard {
  color: var(--kogPlatformGray018);
  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray090);
  border-radius: var(--space-xs);
}
</style>
