<template>
  <div class="LoginCard-errorBox">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'KogLoginErrorBox',
};
</script>

<style scoped>
.LoginCard-errorBox {
  margin-bottom: var(--space-m);
  padding: 20px var(--space-l);

  color: var(--kogPlatformRedDarken40);

  background-color: var(--kogPlatformRedLighten20);
  border: 1px solid var(--kogPlatformRedLighten10);
  border-radius: var(--space-xs);
}

.LoginCard-errorBox a {
  color: var(--kogPlatformRedDarken40);
  text-decoration: underline;
}
</style>
