<script setup lang="ts">
import { computed } from 'vue';

import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import TagRadioGroup from 'sharedApp/components/filter/tag-radio-group.vue';

type Option = {
  label: string;
  value: string;
};

type Emits = {
  'update:modelValue': [string | null];
};

type Props = {
  options: Option[];
  modelValue: string | null;
  includeOther?: boolean;
  otherInputLabel?: string;
  description: string;
  disabled?: boolean;
};

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
  includeOther: false,
  otherInputLabel: 'Please provide your answer',
  disabled: false,
});

const otherOption = { label: 'Other', value: 'other' } as Option;
const allOptions = computed(() =>
  props.includeOther ? [...props.options, otherOption] : props.options,
);

const isOther = (value?: string | null): value is string =>
  value !== null && !props.options.some(option => option.value === value);

const selectedValue = computed(() => {
  return isOther(props.modelValue) ? 'other' : props.modelValue;
});

const otherInputValue = computed(() => {
  return isOther(props.modelValue) ? props.modelValue : '';
});

const onSelect = (value: string) => {
  let newValue: string | null = value;
  if (value === selectedValue.value) {
    newValue = null;
  } else if (value === 'other') {
    newValue = '';
  }
  emit('update:modelValue', newValue);
};
</script>
<template>
  <div>
    <tag-radio-group
      selected-tag-type="outlineWarning"
      unselected-tag-type="outline"
      size="l"
      :group-label="description"
      :selected-value="selectedValue ?? ''"
      :options="allOptions"
      :disabled="disabled"
      @update:selected-value="onSelect"
    />
    <kog-input
      v-if="props.includeOther"
      class="padd-top-m OptionInput"
      :class="{ 'OptionInput--visible': selectedValue === 'other' }"
      :value="otherInputValue"
      :label="otherInputLabel"
      :is-label-hidden="true"
      :placeholder="otherInputLabel"
      :disabled="disabled"
      @update:value="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<style scoped>
.OptionInput {
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms ease;
}

.OptionInput.OptionInput--visible {
  visibility: visible;
  opacity: 1;
}
</style>
