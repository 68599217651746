<template>
  <div
    ref="content"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltip,
      boundary: 'document.body',
    }"
    :class="getLineClampClass"
  >
    {{ text }}
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { VTooltip } from 'floating-vue';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'KogTextTrim',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    /**
     * The text to be displayed
     */
    text: {
      type: String,
      default: null,
    },
    /**
     * Maximum number of lines to show before trimming occurs
     */
    maxLines: {
      type: Number,
      required: true,
      validation(maxLines) {
        return maxLines >= 0 && maxLines <= 6;
      },
    },
  },
  data() {
    return {
      isShowingTooltip: () => true,
      resizeObserver: null,
    };
  },
  computed: {
    getLineClampClass() {
      if (this.maxLines === 0) {
        return 'line-clamp-none';
      }

      return `line-clamp-${this.maxLines}`;
    },
    tooltip() {
      return this.isShowingTooltip ? this.text : null;
    },
  },
  mounted() {
    this.checkShouldShowTooltip();
    this.resizeObserver = new ResizeObserver(this.checkShouldShowTooltip).observe(
      this.$refs.content,
    );
  },
  updated() {
    nextTick(() => {
      this.checkShouldShowTooltip();
    });
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.content);
    }
  },
  methods: {
    checkShouldShowTooltip() {
      if (!this.$refs.content) {
        return;
      }
      this.isShowingTooltip = this.$refs.content.scrollHeight > this.$refs.content.offsetHeight;
    },
  },
};
</script>
