<template>
  <div>
    <kog-alert class="margin-bottom-xxl">
      You do not seem to have permissions to access {{ nextUrl }}
      <br />
      Please log in to access this page.
    </kog-alert>
  </div>
</template>

<script>
import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';

export default {
  name: 'UnauthenticatedWarningBox',
  components: {
    KogAlert,
  },
  computed: {
    nextUrl() {
      const { next } = { ...this.$route.query };
      return next;
    },
  },
};
</script>
