<template>
  <div class="flexContainer flexContainer-center padd-top-xxl">
    <div class="ResetPasswordCard padd-top-xl padd-left-xxl padd-right-xxl padd-bottom-xl">
      <router-link
        to="/"
        class="LoginCard-backToLoginLink"
      >
        <kog-icon
          size="s"
          icon-class="fa-chevron-left"
          theme="custom"
        />Back
      </router-link>
      <h1 class="heading-s sm:heading-m margin-top-m margin-bottom-xs">
        {{ defaultHeading }}
        <a
          id="passwordResetMessage"
          class="unstyledLink"
          tabindex="-1"
          >{{ afterResetHeading }}</a
        >
      </h1>
      <div v-if="passwordResetSent">
        <div class="SuccessBox">
          <p> Password reset instructions have now been sent to your inbox! </p>
          <p>
            If the email exists on Kognity, you should receive it shortly. If you cannot find it,
            look in your email's social, spam or campaign folder.
          </p>
        </div>
        <router-link
          to="/"
          class="KogButtonLegacy KogButtonLegacy--primary ResetPassword-button margin-top-l"
        >
          Log in
        </router-link>

        <p class="margin-top-l">
          Didn't receive the email?
          <a
            href="#"
            @click.prevent="onClickResend()"
            >Resend</a
          >
        </p>
      </div>
      <kog-login-error-box v-if="passwordResetFail">
        <p>
          We could not reset your password. Check your network connection or try again in a few
          minutes!
        </p>
        <p> If the issue persists, please contact our support team! </p>
      </kog-login-error-box>
      <form
        v-if="showPasswordResetForm"
        @submit.prevent="sendResetPasswordRequest()"
      >
        <p class="text-small"> We'll email you instructions to reset your password. </p>
        <label
          for="email"
          class="text-normal margin-bottom-xxs"
        >
          Email
        </label>
        <input
          id="email"
          v-model="email"
          class="KogFormInput padd-xs padd-left-s padd-right-s"
          type="text"
          autocomplete="email"
          name="email"
          placeholder="Enter your email"
        />
        <button
          class="KogButtonLegacy KogButtonLegacy--primary ResetPassword-button margin-top-l"
          :disabled="resetButtonDisabled"
        >
          <span v-if="$wait.waiting('sending_password_reset')">
            <i class="fa fa-spinner fa-spin fa-1x fa-fw" />
          </span>
          <span v-else> Reset password </span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { resetPassword } from 'loginApp/api/logins.js';
import KogLoginErrorBox from 'loginApp/components/kog-login-error-box.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'ResetPasswordContainer',
  components: {
    KogIcon,
    KogLoginErrorBox,
  },
  data() {
    return {
      email: null,
      passwordResetSent: false,
      passwordResetFail: false,
      defaultHeading: 'Password Reset',
      afterResetHeading: '',
    };
  },
  computed: {
    validEmail() {
      const re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },
    resetButtonDisabled() {
      return !this.validEmail || this.$wait.waiting('sending_password_reset');
    },
    resetPasswordAttempted() {
      return this.passwordResetSent === true || this.passwordResetFail === true;
    },
    showPasswordResetForm() {
      return !this.resetPasswordAttempted || this.passwordResetFail === true;
    },
  },
  methods: {
    async sendResetPasswordRequest() {
      this.$wait.start('sending_password_reset');
      try {
        await resetPassword(this.email);
        this.defaultHeading = '';
        this.afterResetHeading = 'Password Reset Instructions Sent';
        this.passwordResetSent = true;
        this.passwordResetFail = false;
      } catch {
        this.passwordResetSent = false;
        this.defaultHeading = '';
        this.afterResetHeading = 'Password Reset Failed!';
        this.passwordResetFail = true;
      } finally {
        this.$wait.end('sending_password_reset');
        const passwordResetMessage = document.getElementById('passwordResetMessage');
        passwordResetMessage.focus();
      }
    },
    onClickResend() {
      this.passwordResetSent = false;
      this.passwordResetFail = false;
    },
  },
};
</script>

<style scoped>
.ResetPasswordCard {
  width: 300px;
  min-width: 300px;

  font-family: var(--kog-satoshi);
  color: var(--kogPlatformGray018);

  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray090);
  border-radius: var(--space-xs);
}

.LoginCard-backToLoginLink {
  color: var(--kog-link-color);
}

.LoginCard-backToLoginLink:hover {
  color: var(--kog-link-hover-color);
}

.ResetPassword-button {
  width: 100%;
}

.SuccessBox {
  padding: 20px var(--space-l);

  font-family: var(--kog-satoshi);
  color: var(--kog-text-default);

  background-color: var(--kog-alert-success-background);
  border-radius: var(--space-xs);
}

.unstyledLink {
  color: var(--kogPlatformGray018);
  text-decoration: none;
}

@media screen and (min-width: 576px) {
  .ResetPasswordCard {
    width: 390px;
    min-width: 390px;
  }
}
</style>
