import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import FloatingVue from 'floating-vue';
import { createVueWait } from 'vue-wait';

import SentryInit from 'sharedApp/libs/sentry-vue.js';
import MixpanelPlugin from 'sharedApp/plugins/tracking/mixpanel/mixpanel-plugin.js';

import 'sharedApp/polyfills.js';
import 'sharedApp/styles/index.js';

import themes from 'sharedApp/components/tooltip/themes.ts';
import KogDirectives from 'sharedApp/directives/kog-directives.js';

import '../vendor.js';

import { createPinia } from 'pinia';

import App from './app.vue';
import router from './router.js';

const head = createHead();
const VueWait = createVueWait({
  useVuex: false,
  registerDirective: false,
});

const pinia = createPinia();
const app = createApp(App);
app.use(head);
app.use(KogDirectives);
app.use(FloatingVue, { themes });
app.use(VueWait);
app.use(router);
app.use(pinia);
app.use(MixpanelPlugin);

SentryInit(app, router);

router.isReady().then(() => {
  app.mount('#login-app');
});
