<template>
  <div
    v-tooltip="{
      theme: 'kog-tooltip',
      content: mainTooltipText,
      boundary: 'document.body',
    }"
    class="ProgressBar"
    :class="rootContainerClass"
  >
    <div
      v-for="(item, index) in progressValues"
      :key="index"
      class="ProgressBar-barContainer flexContainer"
      :class="getBarContainerClass(index)"
      :style="`width: ${item.percentage}%`"
    >
      <div
        v-tooltip="{
          theme: 'kog-tooltip',
          content: item.tooltipText,
          boundary: 'document.body',
        }"
        class="flexChild-size-1"
        :class="item.isEmptyBar ? 'ProgressBar-emptyBar' : 'ProgressBar-bar'"
        :aria-label="item.tooltipText || item.label"
        :aria-labelledby="item.labelledBy"
        :aria-hidden="!item.label && !item.tooltipText && !item.labelledBy ? 'true' : null"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        :aria-valuenow="item.percentage"
      />
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import { isZeroToHundred } from 'sharedApp/utils/validators.js';

export default {
  name: 'KogProgressBar',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    /**
     * A number or array indicating the values and tooltips for all bar segments.
     */
    progress: {
      default: 0,
      validator: prop => {
        const isNumber = typeof prop === 'number';
        const isArray = Array.isArray(prop);
        const isValidType = isNumber || isArray;
        if (!isValidType) {
          return false;
        }
        if (isNumber) {
          return isZeroToHundred(prop);
        }
        if (isArray) {
          const percentageTotal = prop.reduce(
            (tempSum, curr) => tempSum + (curr.percentage || 0),
            0,
          );
          return isZeroToHundred(percentageTotal);
        }
        return false;
      },
    },
    /**
     * Sets the height of the progress bar.
     */
    size: {
      type: String,
      default: 'm',
      validator: prop => ['xs', 's', 'm', 'l'].includes(prop),
    },
    /**
     * Controls the bar colors. There are five predefined sets.
     */
    type: {
      type: String,
      default: 'positive',
      validator: prop => ['primary', 'mixed', 'positive', 'warning', 'negative'].includes(prop),
    },
    /**
     * A tooltip to show when hovering the entire component.
     */
    mainTooltipText: {
      type: String,
      default: '',
    },
    /**
     * The id of an external element that serves as a label for the progress bar.
     * Will only be used when `progress` is a number.
     */
    labelledBy: {
      type: String,
      default: '',
    },
  },
  computed: {
    isSingleBar() {
      return (
        this.isNumberProgress ||
        this.progress.filter(item => item.percentage || item.percentage === 0).length === 1
      );
    },
    isNumberProgress() {
      return typeof this.progress === 'number';
    },
    sizeClass() {
      return `ProgressBar--${this.size}`;
    },
    typeClass() {
      return `ProgressBar--${this.type}`;
    },
    progressValues() {
      const barItems = [];
      let percentageTotal = 0;
      let isEmptyBarAdded = false;

      if (this.isNumberProgress) {
        percentageTotal = isZeroToHundred(this.progress) ? this.progress : 0;
        barItems.push({
          percentage: percentageTotal,
          label: this.mainTooltipText,
          labelledBy: this.labelledBy,
        });
      } else {
        this.progress.forEach(item => {
          if (item.percentage || item.percentage === 0) {
            percentageTotal += isZeroToHundred(item.percentage) ? item.percentage : 0;
            barItems.push(item);
          } else if (!isEmptyBarAdded) {
            isEmptyBarAdded = true;
            barItems.push({
              tooltipText: item.tooltipText,
              isEmptyBar: true,
              percentage: isZeroToHundred(percentageTotal) ? 100 - percentageTotal : 0,
            });
          }
        });
      }

      if (!isEmptyBarAdded) {
        barItems.push({
          isEmptyBar: true,
          percentage: isZeroToHundred(percentageTotal) ? 100 - percentageTotal : 0,
        });
      }

      return barItems;
    },
    rootContainerClass() {
      return [
        this.sizeClass,
        this.typeClass,
        this.isSingleBar ? 'ProgressBar--single' : 'ProgressBar--double',
      ];
    },
  },
  methods: {
    getBarContainerClass(index) {
      // bar containers are used for seamless intersection between subsequent progress bars
      // their background is set to match the color of the neighboring bar
      const barContainerClass = `ProgressBar-bar${index + 1}Container`;
      let backgroundClass = '';
      if (index === 0 && this.progress[1] && this.progress[1].percentage > 0) {
        backgroundClass = `${barContainerClass}--blend`;
      }

      return [barContainerClass, backgroundClass];
    },
  },
};
</script>

<style scoped>
.ProgressBar {
  overflow: hidden;
  display: flex;
  width: 100%;
  background: var(--kog-progressbar-default-background);
}

.ProgressBar-barContainer {
  overflow: hidden;
}

.ProgressBar-barContainer,
.ProgressBar-bar {
  height: 100%;
  transition: width 0.6s ease;
}

/* stylelint-disable no-descending-specificity */

/* begin height and border radius based on size */

.ProgressBar.ProgressBar--xs {
  height: 4px;
}

.ProgressBar.ProgressBar--s {
  height: 8px;
}

.ProgressBar.ProgressBar--m {
  height: 14px;
}

.ProgressBar.ProgressBar--l {
  height: 40px;
}

.ProgressBar.ProgressBar--xs,
.ProgressBar.ProgressBar--xs .ProgressBar-bar {
  border-radius: 2px;
}

.ProgressBar.ProgressBar--s,
.ProgressBar.ProgressBar--s .ProgressBar-bar {
  border-radius: 4px;
}

.ProgressBar.ProgressBar--m,
.ProgressBar.ProgressBar--m .ProgressBar-bar {
  border-radius: 7px;
}

.ProgressBar.ProgressBar--l,
.ProgressBar.ProgressBar--l .ProgressBar-bar {
  border-radius: 20px;
}

.ProgressBar.ProgressBar--double.ProgressBar--xs .ProgressBar-bar2Container {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ProgressBar.ProgressBar--double.ProgressBar--s .ProgressBar-bar2Container {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ProgressBar.ProgressBar--double.ProgressBar--m .ProgressBar-bar2Container {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.ProgressBar.ProgressBar--double.ProgressBar--l .ProgressBar-bar2Container {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* end height and border radius based on size */

/* begin colors based on type for single bar */

.ProgressBar--single.ProgressBar--primary {
  background: var(--kog-progressbar-single-primary-background);
}

.ProgressBar--single.ProgressBar--positive {
  background: var(--kog-progressbar-single-positive-background);
}

.ProgressBar--single.ProgressBar--negative {
  background: var(--kog-progressbar-single-negative-background);
}

.ProgressBar--single.ProgressBar--warning {
  background: var(--kog-progressbar-single-warning-background);
}

.ProgressBar--single.ProgressBar--mixed {
  background: var(--kog-progressbar-single-mixed-background);
}

.ProgressBar--single.ProgressBar--primary .ProgressBar-bar {
  background: var(--kog-progressbar-single-primary-color);
}

.ProgressBar--single.ProgressBar--positive .ProgressBar-bar {
  background: var(--kog-progressbar-single-positive-color);
}

.ProgressBar--single.ProgressBar--negative .ProgressBar-bar {
  background: var(--kog-progressbar-single-negative-color);
}

.ProgressBar--single.ProgressBar--warning .ProgressBar-bar {
  background: var(--kog-progressbar-single-warning-color);
}

.ProgressBar--single.ProgressBar--mixed .ProgressBar-bar {
  background: var(--kog-progressbar-single-mixed-color);
}

/* end colors based on type for single bar */

/* begin colors based on type for double bar */

.ProgressBar-emptyBar,
.ProgressBar--double .ProgressBar-bar1Container {
  background: transparent;
}

.ProgressBar--double.ProgressBar--primary .ProgressBar-bar1Container .ProgressBar-bar {
  background: var(--kog-progressbar-double-primary-bar1-color);
}

.ProgressBar--double.ProgressBar--positive .ProgressBar-bar1Container .ProgressBar-bar {
  background: var(--kog-progressbar-double-positive-bar1-color);
}

.ProgressBar--double.ProgressBar--negative .ProgressBar-bar1Container .ProgressBar-bar {
  background: var(--kog-progressbar-double-negative-bar1-color);
}

.ProgressBar--double.ProgressBar--warning .ProgressBar-bar1Container .ProgressBar-bar {
  background: var(--kog-progressbar-double-warning-bar1-color);
}

.ProgressBar--double.ProgressBar--mixed .ProgressBar-bar1Container .ProgressBar-bar {
  background: var(--kog-progressbar-double-mixed-bar1-color);
}

.ProgressBar--double.ProgressBar--primary .ProgressBar-bar2Container,
.ProgressBar--double.ProgressBar--primary .ProgressBar-bar2Container .ProgressBar-bar,
.ProgressBar--double.ProgressBar--primary .ProgressBar-bar1Container--blend {
  background: var(--kog-progressbar-double-primary-bar2-color);
}

.ProgressBar--double.ProgressBar--positive .ProgressBar-bar2Container,
.ProgressBar--double.ProgressBar--positive .ProgressBar-bar2Container .ProgressBar-bar,
.ProgressBar--double.ProgressBar--positive .ProgressBar-bar1Container--blend {
  background: var(--kog-progressbar-double-positive-bar2-color);
}

.ProgressBar--double.ProgressBar--negative .ProgressBar-bar2Container,
.ProgressBar--double.ProgressBar--negative .ProgressBar-bar2Container .ProgressBar-bar,
.ProgressBar--double.ProgressBar--negative .ProgressBar-bar1Container--blend {
  background: var(--kog-progressbar-double-negative-bar2-color);
}

.ProgressBar--double.ProgressBar--warning .ProgressBar-bar2Container,
.ProgressBar--double.ProgressBar--warning .ProgressBar-bar2Container .ProgressBar-bar,
.ProgressBar--double.ProgressBar--warning .ProgressBar-bar1Container--blend {
  background: var(--kog-progressbar-double-warning-bar2-color);
}

.ProgressBar--double.ProgressBar--mixed .ProgressBar-bar2Container,
.ProgressBar--double.ProgressBar--mixed .ProgressBar-bar2Container .ProgressBar-bar,
.ProgressBar--double.ProgressBar--mixed .ProgressBar-bar1Container--blend {
  background: var(--kog-progressbar-double-mixed-bar2-color);
}

/* end colors based on type for double bar */

/* stylelint-enable */
</style>
