<template>
  <div
    role="alert"
    class="KogAlert flexContainer padd-m padd-left-l"
    :class="cssClassMode"
    :aria-label="typeAriaLabel"
  >
    <div class="flexChild-noGrow margin-right-s">
      <kog-font-awesome-icon
        :fa-class="alertIcon"
        fa-style="regular"
        size="s"
      />
    </div>
    <div class="flexChild-size-1">
      <p
        v-if="title"
        class="heading-xs"
      >
        {{ title }}
      </p>
      <!-- @slot Alert message content -->
      <slot />
    </div>
  </div>
</template>

<script>
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

export default {
  name: 'KogAlert',
  components: {
    KogFontAwesomeIcon,
  },
  props: {
    /**
     * Accepted values are 'info', 'success', 'warning', or 'error'.
     */
    mode: {
      type: String,
      default: 'warning',
      validator: mode => ['info', 'success', 'warning', 'error'].includes(mode),
    },
    /**
     * The alert message title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Custom icon (overrides the default icon computed for each mode)
     */
    iconClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    cssClassMode() {
      return `KogAlert-${this.mode}`;
    },
    alertIcon() {
      return this.iconClass || this.iconClassMode;
    },
    iconClassMode() {
      switch (this.mode) {
        case 'info':
          return 'fa-info-circle';
        case 'error':
          return 'fa-times-circle';
        case 'success':
          return 'fa-check-circle';
        default:
          return 'fa-exclamation-triangle';
      }
    },
    typeAriaLabel() {
      switch (this.mode) {
        case 'warning':
        case 'error':
          return this.mode;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.KogAlert {
  width: 100%;
  font-family: var(--kog-satoshi);
  line-height: var(--space-l);
  border-radius: var(--space-xs);
}

.KogAlert-info {
  background-color: var(--kog-alert-info-background);
}

.KogAlert-success {
  background-color: var(--kog-alert-success-background);
}

.KogAlert-warning {
  background-color: var(--kog-alert-warning-background);
}

.KogAlert-error {
  background-color: var(--kog-alert-error-background);
}
</style>
