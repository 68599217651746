import { createRouter, createWebHistory } from 'vue-router';

import setPageTitle from 'sharedApp/vue-utils/router-utils.js';

import DefaultLayout from './layouts/default-layout.vue';
import AcceptInvitation from './pages/accept-invitation/accept-invitation.vue';
import AcceptWithPassword from './pages/accept-invitation/accept-with-password.vue';
import AcceptWithSso from './pages/accept-invitation/accept-with-sso.vue';
import ClassicLogin from './pages/classic-login.vue';
import Login from './pages/login/login.vue';
import Maintenance from './pages/maintenance.vue';
import Onboarding from './pages/onboarding/onboarding.vue';
import OriginUnreachable from './pages/origin-unreachable.vue';
import ResetPassword from './pages/reset-password.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      children: [
        {
          path: '',
          component: Login,
          name: 'login-page',
          meta: {
            title: 'Log in to Kognity | Kognity',
          },
        },
        {
          path: 'classic-login',
          name: 'classic-login-page',
          component: ClassicLogin,
          meta: {
            title: 'Log in to Kognity | Kognity',
          },
        },
        {
          path: '/password-reset',
          name: 'password-reset',
          component: ResetPassword,
          meta: {
            title: 'Reset your password | Kognity',
          },
        },
        {
          path: '/maintenance',
          name: 'maintenance',
          component: Maintenance,
          meta: {
            title: 'Scheduled Maintenance | Kognity',
          },
        },
        {
          path: '/origin-unreachable',
          name: 'origin-unreachable',
          component: OriginUnreachable,
          meta: {
            title: 'Experiencing Difficulties | Kognity',
          },
        },
      ],
    },
    {
      path: '/accept-invitation/:userId/:token',
      name: 'accept-invitation',
      component: AcceptInvitation,
      meta: {
        title: 'Welcome to Kognity | Kognity',
      },
      children: [
        {
          name: 'sso-container',
          path: '',
          component: AcceptWithSso,
        },
        {
          path: 'set-password',
          name: 'set-password',
          component: AcceptWithPassword,
        },
      ],
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: {
        title: 'Onboarding to Kognity | Kognity',
      },
    },
  ],
});

router.afterEach(setPageTitle);

export default router;
