import type { Component } from 'vue';

import useOnboardingStore from 'loginApp/store/modules/onboarding.ts';

import ClassSetup from './steps/class-setup.vue';
import SelectInterests from './steps/select-interests.vue';
import SelectRole from './steps/select-role.vue';

export type StepName = 'role' | 'interests' | 'classSetup';

type Step = {
  component: Component;
  progress: number;
  nextStepResolver?: () => StepName;
};

const getRoleNextStep = (): StepName => {
  const store = useOnboardingStore();

  const shouldShowClassSetup =
    store.userRole !== 'school_leader' && store.teacherClasses.length > 0 && !store.isDemoUser;

  if (shouldShowClassSetup) {
    return 'classSetup';
  }

  return 'interests';
};

export const steps: Record<StepName, Step> = {
  role: {
    component: SelectRole,
    nextStepResolver: getRoleNextStep,
    progress: 50,
  },
  classSetup: {
    component: ClassSetup,
    nextStepResolver: () => 'interests',
    progress: 75,
  },
  interests: {
    component: SelectInterests,
    progress: 100,
  },
};

export type StepProps = {
  isLoading?: boolean;
  isLastStep: boolean;
  progress: number;
};
