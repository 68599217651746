<template>
  <div
    :class="classAttr"
    :style="styleAttr"
  >
    <div class="KogInput-labelContainer">
      <div
        class="flexChild-canGrow heading-xxs"
        :class="{
          'margin-bottom-xxs': (label && !isLabelHidden) || labelIcon || topRightHelperText,
        }"
      >
        <span
          v-if="labelIcon"
          aria-hidden="true"
          class="KogInput-labelIcon"
        >
          <i :class="`far fa-${labelIcon}`" />
        </span>
        <label
          v-if="label && !isLabelHidden"
          :for="getUniqueId('input')"
          aria-hidden="true"
          class="KogInput-label"
        >
          {{ label }}
        </label>
      </div>
      <kog-tag
        v-if="topRightHelperText"
        :id="getUniqueId('top-info')"
        type="basic"
        :label="topRightHelperText"
      />
    </div>
    <div class="KogInput-inputContainer">
      <input
        v-bind="otherAttrs"
        :id="getUniqueId('input')"
        ref="kogInput"
        class="KogInput"
        :class="inputClass"
        :aria-label="label"
        :aria-describedby="getIdsForAriaDescribedby"
        :size="size"
        :type="type"
        :value="value"
        @input="handleInput"
        @blur="handleBlur"
        @focus="handleFocus"
      />
      <span
        v-if="inputIcon"
        class="KogInput-icon"
        :class="{
          'KogInput-icon--right': iconPosition === 'right',
        }"
      >
        <kog-round-button
          v-if="isIconClickable"
          :aria-label="clickableIconLabel"
          :icon-class="`far fa-${inputIcon}`"
          icon-style="light"
          size="x-small"
          button-style="secondary-basic"
          type="button"
          @click.prevent="handleIconClick"
        />
        <i
          v-else
          :class="`far fa-${inputIcon}`"
        />
      </span>
    </div>

    <div
      :id="getUniqueId('bottom-info')"
      :aria-label="displayedValidationMessage || helperText"
    >
      <span
        v-if="displayedValidationMessage"
        role="alert"
        class="KogInput-error"
      >
        {{ displayedValidationMessage }}
      </span>
      <span
        v-else-if="helperText"
        class="KogInput-helper"
      >
        {{ helperText }}
      </span>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';

export default {
  name: 'KogInput',
  components: {
    KogTag,
    KogRoundButton,
  },
  inheritAttrs: false,
  props: {
    /**
     * The value of the input
     */
    value: {
      type: [String, Number],
      default: '',
    },
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use `isLabelHidden: true`
     */
    label: {
      type: String,
      required: true,
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Class of a font-awesome icon to be shown inside the input
     */
    inputIcon: {
      type: String,
      default: '',
    },
    /**
     * Class of a font-awesome icon to be shown before the label
     */
    labelIcon: {
      type: String,
      default: '',
    },
    /**
     * Position of the icon inside the input
     */
    iconPosition: {
      type: String,
      default: 'left',
      validator: prop => ['left', 'right'].includes(prop),
    },
    /**
     * If true the click on the icon produces the 'icon-click' event
     */
    isIconClickable: {
      type: Boolean,
      default: false,
    },
    /**
     * Clickable icon label for accessibility purposes
     */
    clickableIconLabel: {
      type: String,
      default: '',
    },
    /**
     * A description or additional information to be displayed below the input
     */
    helperText: {
      type: String,
      default: '',
    },
    /**
     * A description or additional information to be displayed
     *  at the top-right corner of the input
     */
    topRightHelperText: {
      type: String,
      default: '',
    },
    /**
     * Sets the native input "type" attribute
     */
    type: {
      type: String,
      default: 'text',
      validator: prop => ['text', 'email', 'password', 'number', 'url'].includes(prop),
    },
    /**
     * Specifies style of the input field
     */
    styleType: {
      type: String,
      default: 'outline',
      validator: val => ['outline', 'prominent'].includes(val),
    },
    /**
     * Sets the native input "size" attribute
     */
    size: {
      type: Number,
      default: 20,
    },
    /**
     * Specify whether the validation message should be updated while changing the value
     * or when the control loses focus.
     */
    validateOn: {
      type: String,
      default: 'input',
      validator: prop => ['input', 'blur', 'none'].includes(prop),
    },
    /**
     * Shown instead of inner inputs validation message if input validation is not valid.
     */
    validationMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'blur', 'icon-click', 'update:value'],
  setup() {
    const { getUniqueId } = useUniqueId();
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      getUniqueId,
      ...extractedAttrs,
    };
  },
  data: () => ({
    hasFocus: false,
    shouldValidate: false,
    validationClass: '',
    displayedValidationMessage: '',
    validity: { valid: true },
  }),
  computed: {
    getIdsForAriaDescribedby() {
      const ids = [];
      if (this.topRightHelperText) {
        ids.push(this.getUniqueId('top-info'));
      }
      if (this.displayedValidationMessage || this.helperText) {
        ids.push(this.getUniqueId('bottom-info'));
      }
      return ids.join(' ');
    },
    inputClass() {
      const classes = [this.validationClass, `KogInput--${this.styleType}`];

      if (this.inputIcon) {
        classes.push(
          this.iconPosition === 'left'
            ? 'KogInput--withLeftInputIcon'
            : 'KogInput--withRightInputIcon',
        );
      }

      return classes;
    },
  },
  watch: {
    value() {
      if (!this.shouldValidate) {
        return;
      }

      if (!this.hasFocus || this.validateOn === 'input') {
        nextTick(() => {
          this.checkValidity();
        });
      }
    },
  },
  methods: {
    handleInput(event) {
      /**
       * Emitted when the user changes the value
       *
       * @property {string} value
       */
      this.$emit('update:value', event.target.value);
      this.$emit('input', event.target.value);
    },
    handleBlur(event) {
      this.hasFocus = false;
      if (this.validateOn === 'blur') {
        this.checkValidity();
      }
      /**
       * Emitted when the input loses focus
       *
       * @property {object} event original event object emitted by the native input `blur`
       */
      this.$emit('blur', event);
    },
    handleFocus() {
      this.hasFocus = true;
      this.shouldValidate = true;
    },
    handleIconClick() {
      this.$emit('icon-click');
    },
    // eslint-disable-next-line vue/no-unused-properties
    focus() {
      this.$refs.kogInput.focus();
    },
    checkValidity() {
      if (this.validateOn === 'none') {
        return;
      }
      this.validity = this.$refs.kogInput.validity;
      if (this.validity.valid) {
        this.clearValidationMessage();
      } else {
        const validationMessage = this.validationMessage || this.$refs.kogInput.validationMessage;
        this.setValidationMessage(validationMessage);
      }
    },
    clearValidationMessage() {
      this.validationClass = '';
      this.displayedValidationMessage = '';
    },
    setValidationMessage(message) {
      this.validationClass = 'KogInput--validation';
      this.displayedValidationMessage = message;
    },
  },
};
</script>

<style scoped>
.KogInput-inputContainer {
  --icon-container-width: 40px; /* stylelint-disable-line */

  position: relative;
}

.KogInput {
  border-style: solid;
  font-weight: normal;
  line-height: 24px;
  min-height: 40px;
  padding: var(--space-xs) var(--space-s);
  width: 100%;
}

.KogInput--outline {
  background-color: var(--kog-input-outline-background-color);
  border-color: var(--kog-input-outline-border-color);
  border-radius: 8px;
  border-width: 1px;
}

.KogInput--prominent {
  background-color: var(--kog-input-prominent-background-color);
  border-color: var(--kog-input-prominent-border-color);
  border-width: 0 0 1px 0;
  padding-bottom: calc(var(--space-xs) + 1px);
}

.KogInput--withLeftInputIcon {
  padding-left: var(--icon-container-width);
}

.KogInput--withRightInputIcon {
  padding-right: var(--icon-container-width);
}

.KogInput-icon {
  align-items: center;
  color: var(--kog-input-icon-color);
  display: flex;
  height: 40px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: var(--icon-container-width);
}

.KogInput-icon--right {
  left: auto;
  right: 0;
}

.KogInput-label {
  cursor: default;
  display: inline-block;
  margin: 0;
}

.KogInput-labelIcon {
  color: var(--kog-input-icon-color);
  justify-content: center;
  width: var(--space-l);
}

.KogInput::placeholder {
  color: var(--kog-input-placeholder-color);
}

.KogInput-helper,
.KogInput-error {
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  margin-top: var(--space-xxs);
}

.KogInput-error {
  color: var(--kog-input-error-text-color);
}

.KogInput-helper {
  color: var(--kog-input-helper-text-color);
}

.KogInput-labelContainer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.KogInput:focus,
.KogInput:focus-visible {
  box-shadow: none;
  outline: none;
}

.KogInput--outline:focus,
.KogInput--outline:focus-visible {
  border-color: var(--kog-input-outline-focus-border-color);
}

.KogInput--prominent:focus,
.KogInput--prominent:focus-visible {
  border-color: var(--kog-input-prominent-focus-border-color);
}

.KogInput:focus::placeholder,
.KogInput:focus-visible::placeholder {
  color: var(--kog-input-focus-placeholder-color);
}

.KogInput--outline:disabled {
  background-color: var(--kog-input-outline-disabled-background-color);
  border-color: var(--kog-input-outline-disabled-border-color);
}

.KogInput--prominent:disabled {
  background-color: var(--kog-input-prominent-disabled-background-color);
  border-color: var(--kog-input-prominent-disabled-border-color);
}

.KogInput:disabled::placeholder {
  color: var(--kog-input-disabled-placeholder-color);
}

.KogInput--prominent:focus,
.KogInput--prominent:focus-visible,
.KogInput--prominent:hover:not(:disabled),
.KogInput--prominent.KogInput--validation {
  border-bottom-width: 2px;
  padding-bottom: var(--space-xs);
}

.KogInput--outline:hover:not(:disabled):not(:focus) {
  border-color: var(--kog-input-outline-hover-border-color);
}

.KogInput--prominent:hover:not(:disabled):not(:focus) {
  border-color: var(--kog-input-prominent-hover-border-color);
}

.KogInput--outline.KogInput--validation {
  border-color: var(--kog-input-outline-error-border-color) !important;
}

.KogInput--prominent.KogInput--validation {
  border-color: var(--kog-input-prominent-error-border-color) !important;
}

.KogInput--validation::placeholder,
.KogInput--validation:focus::placeholder {
  color: var(--kog-input-error-placeholder-color);
}
</style>
