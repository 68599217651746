<template>
  <header
    class="Navbar"
    :class="{
      'Navbar--transparent': isTransparent,
    }"
    @mouseleave.stop="$emit('on-mouse-leave')"
    @blur.stop="$emit('on-mouse-leave')"
  >
    <div
      class="Navbar-mainMenu flexContainer flexContainer-spaceBetween flexContainer-center padd-left-s padd-right-s"
    >
      <div class="Navbar-leftMenu flexContainer">
        <slot name="leftCornerSlot" />
        <div class="Navbar-leftSlot">
          <slot name="leftSlot" />
        </div>
      </div>
      <div
        v-if="!rightSlotExpanded && $slots['middleSlot']"
        class="Navbar-middleMenu flexContainer"
      >
        <slot name="middleSlot" />
      </div>
      <div
        v-if="$slots['rightSlot']"
        class="Navbar-rightMenu flexContainer flexContainer-flexEnd"
      >
        <slot name="rightSlot" />
      </div>
    </div>
    <div
      v-if="$slots['subMenuSlot']"
      :class="{ 'Navbar-divider': !searchExpanded }"
      class="Navbar-subMenu flexContainer"
    >
      <slot name="subMenuSlot" />
    </div>
  </header>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
export default {
  name: 'KogNavbar',
  props: {
    rightSlotExpanded: {
      type: Boolean,
      default: false,
    },
    searchExpanded: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-mouse-leave'],
};
</script>

<style scoped>
.Navbar {
  position: fixed;
  z-index: 500;

  width: 100%;
  height: auto;
  margin: 0;

  font-size: var(--kog-font-size-default);
  color: var(--kog-navigation-button-default);
  text-align: left;

  background-color: var(--kog-navigation-background-default);
  box-shadow: var(--kog-navigation-box-shadow);
}

.Navbar.Navbar--transparent {
  color: var(--kog-colors-white);
  background-color: rgba(0, 0, 0, 0.25);
}

.Navbar-mainMenu {
  height: var(--full-page-navbar-height);
}

.Navbar-subMenu {
  height: 48px;
}

.Navbar-divider {
  border-top: 1px solid var(--kog-navigation-border-subnav);
}

.Navbar-leftMenu,
.Navbar-rightMenu {
  flex: 1;
}

.Navbar-middleMenu {
  flex: 2;
  height: 100%;
}

@media (max-width: 1024px) {
  .Navbar-leftSlot {
    display: none;
  }
}

@media (--viewport-s) {
  .Navbar-middleMenu {
    display: none;
  }
}

.Navbar-hamburgerDropdown {
  width: 320px;
}
</style>
