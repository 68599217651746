<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import type { User } from '@apis/generated';
import type { StepName } from 'loginApp/pages/onboarding/steps.ts';

import { OnboardingService } from '@apis/generated/services.ts';

import reverse from 'learning/common/libs/kog-router.js';

import { steps } from 'loginApp/pages/onboarding/steps.ts';
import useOnboardingStore from 'loginApp/store/modules/onboarding.ts';
import KogNewNavbar from 'sharedApp/components/core/kog-new-navbar.vue';
import DjangoSettings from 'sharedApp/django-settings-module.ts';
import KognityLogoUrl from 'sharedApp/img/kognity-logo-text-green.svg';
import posthog from 'sharedApp/libs/posthog.ts';
import { setPosthogToContinueRecording } from 'sharedApp/utils/posthog-utils.js';

const mixpanel = inject('mixpanel');
const route = useRoute();

const store = useOnboardingStore();

const currentStep = ref<StepName>('role');
const currentStepComponent = computed(() => steps[currentStep.value].component);
const currentStepProgress = computed(() => steps[currentStep.value].progress);
const isLoading = ref(false);

const initializeMixpanel = () => {
  const user = store.user as User;
  const mixpanelUser = {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    id: user.id,
    created: user.date_joined,
  };
  mixpanel.init(DjangoSettings.MIXPANEL.AUTH_TOKEN, { User: mixpanelUser });
  mixpanel.trackEventViaBackend('Activation survey - start', {
    activation__onboarding_version: store.onboardingVersion,
  });
};

const initPosthog = () => {
  const user = store.user as User;
  posthog.init(
    user.id,
    {
      is_internal_user: posthog.isInternalUser(user.email, user.school?.name || ''),
    },
    {
      disable_session_recording: true,
    },
  );
  posthog.startSessionRecording();
  const isTeacher = user.group_names.includes('SchoolTeacher');

  if (isTeacher) {
    setPosthogToContinueRecording();
  }
};

const redirectToStudyApp = () => {
  let url = '/';
  const urlParams = new URLSearchParams(window.location.search);
  const hasAllTargetParams = ['target_slug', 'target_sid', 'target_cid'].every(param =>
    urlParams.has(param),
  );

  if (hasAllTargetParams) {
    url = reverse('studyApp', 'newSubjectClassOverview', {
      classSlug: urlParams.get('target_slug'),
      sid: urlParams.get('target_sid'),
      cid: urlParams.get('target_cid'),
    });
    ['target_slug', 'target_sid', 'target_cid'].forEach(param => urlParams.delete(param));
  } else if (store.prioritizedClass) {
    url = reverse('studyApp', 'newSubjectClassOverview', {
      classSlug: store.prioritizedClass.slug,
      sid: store.prioritizedClass.subject_id,
      cid: store.prioritizedClass.id,
    });
  }

  if (url !== '/') {
    urlParams.set('show_onboarding_modal', 'true');
    url += `?${urlParams.toString()}`;
  }

  window.location.href = url;
};

const handleNextStep = async () => {
  const { nextStepResolver } = steps[currentStep.value];
  if (nextStepResolver) {
    currentStep.value = nextStepResolver();
  } else {
    isLoading.value = true;
    await OnboardingService.onboardingTeacherOnboardingFormUpdate({
      requestBody: {
        selected_role: store.userRole,
        activation_interests: store.userInterests,
        free_text_interests: store.freeTextInterests,
        class_setup_issue: store.classSetupIssue,
        onboarding_version: store.onboardingVersion,
      },
    });
    redirectToStudyApp();
  }
};

store.setOnboardingVersion(route.query.version);

onMounted(async () => {
  await store.fetchUser();
  store.fetchAvailableInterests();
  store.fetchTeacherClasses();
  initializeMixpanel();
  initPosthog();
});
</script>

<template>
  <div>
    <kog-new-navbar>
      <template #leftCornerSlot>
        <img
          :src="KognityLogoUrl"
          alt="Kognity logo"
        />
      </template>
    </kog-new-navbar>
  </div>

  <div class="Onboarding--content">
    <component
      :is="currentStepComponent"
      :is-loading="isLoading"
      :is-last-step="!steps[currentStep].nextStepResolver"
      :progress="currentStepProgress"
      @next="handleNextStep"
    />
  </div>
</template>

<style scoped>
.Onboarding--content {
  overflow-x: hidden;
  padding-top: var(--full-page-navbar-height);
}
</style>
