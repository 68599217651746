<template>
  <div class="flexContainer flexContainer-center padd-top-xxl">
    <div class="Maintenance z-index-1">
      <div class="MaintenanceCard padd-top-xl padd-left-xxl padd-right-xxl padd-bottom-xl">
        <h1 class="heading-s margin-bottom-l"> We are upgrading your learning experience </h1>
        <p>
          <b>Planned maintenance: Kognity will be back in a few minutes!</b>
        </p>
        <p class="margin-top-l"> Hi there, </p>
        <p>
          The technology that powers Kognity is being updated right now and it should take a few
          minutes. We are sorry for any immediate problems this may cause.
        </p>
        <p class="margin-top-l">
          <b>Please check back soon and thank you for your patience!</b>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.Maintenance {
  max-width: 600px;
}

.MaintenanceCard {
  color: var(--kogPlatformGray018);
  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray090);
  border-radius: var(--space-xs);
}
</style>
