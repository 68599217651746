<script setup>
import { computed, reactive, watch } from 'vue';

import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [Object, null],
    default: null,
  },
  inputEnableOptionValue: {
    type: String,
    default: 'other',
  },
  inputPlaceholder: {
    type: String,
    default: 'Please describe',
  },
  inputAlwaysVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:model-value']);

const currentValue = reactive({
  isValid: false,
  radioValue: '',
  customValue: '',
});

const isValid = computed(() => {
  if (currentValue.radioValue === props.inputEnableOptionValue) {
    return currentValue.customValue.trim().length > 0;
  }
  return currentValue.radioValue.length > 0;
});

const updateFromModelValue = () => {
  if (props.modelValue && typeof props.modelValue === 'object') {
    currentValue.radioValue = props.modelValue.radioValue;
    currentValue.customValue = props.modelValue.customValue;
    currentValue.isValid = isValid.value;
  }
};

const isCustomInputVisible = computed(() => {
  return props.inputAlwaysVisible || currentValue.radioValue === props.inputEnableOptionValue;
});

const getModelValue = () => {
  return {
    radioValue: currentValue.radioValue,
    customValue: isCustomInputVisible.value ? currentValue.customValue : '',
    isValid: isValid.value,
  };
};

const emitModelValue = () => {
  emit('update:model-value', getModelValue());
};

const handleInputValueChange = () => {
  currentValue.radioValue = props.inputEnableOptionValue;
  emitModelValue();
};

watch(
  () => props.modelValue,
  () => {
    updateFromModelValue();
  },
  { immediate: true, deep: true },
);
</script>

<template>
  <div>
    <kog-radio-group
      v-model:selected-value="currentValue.radioValue"
      :options="options"
      @update:selected-value="emitModelValue"
    />
    <kog-input
      v-show="isCustomInputVisible"
      v-model:value="currentValue.customValue"
      :placeholder="inputPlaceholder"
      class="margin-left-xl"
      label="Other"
      is-label-hidden
      @update:value="handleInputValueChange"
    />
  </div>
</template>

<style scoped></style>
