import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import type {
  ActivationInterest,
  OnboardingIssue,
  OnboardingVersionEnum,
  Subject,
  TeacherSubjectClassDetails,
  User,
} from '@apis/generated';

import {
  AccountsService,
  OnboardingService,
  OnboardingVersionEnumEnum,
  SubjectClassesService,
  SubjectsService,
} from '@apis/generated';

import {
  isIBDP,
  isIGCSE,
  isNgss,
} from 'sharedApp/services/educationSystem/education-system-service.js';

export type TeacherSubjectClassDetailsWithSubject = TeacherSubjectClassDetails & {
  subject: Subject;
};

export const INITIAL_CLASSES_LIMIT = 20;

const fetchSubjectsById = async (subjectIds: number[]): Promise<Map<number, Subject>> => {
  const subjects = await SubjectsService.subjectsList({
    idIn: subjectIds,
    pageSize: subjectIds.length,
  });

  if (!subjects.results) {
    return new Map();
  }
  return subjects.results.reduce((acc, subject) => {
    acc.set(subject.id, subject);
    return acc;
  }, new Map());
};

const fetchTeacherSubjectClasses = (
  teacherUserId: number,
  isExpired: boolean = false,
  pageSize: number = INITIAL_CLASSES_LIMIT,
) => {
  return SubjectClassesService.subjectClassesList({
    teacherUserIds: [teacherUserId],
    isExpired,
    pageSize,
  });
};

export const isOnboardingVersion = (value: unknown): value is OnboardingVersionEnum =>
  typeof value === 'string' && Object.values(OnboardingVersionEnumEnum).includes(value as never);

const useOnboardingStore = defineStore('onboarding', () => {
  const user = ref<User>();
  const userRole = ref<string>('');
  const userInterests = ref<string[]>([]);
  const freeTextInterests = ref<string>('');
  const classSetupIssue = ref<OnboardingIssue>();

  const availableInterests = ref<ActivationInterest[]>([]);
  const teacherClasses = ref<TeacherSubjectClassDetailsWithSubject[]>([]);
  const totalTeacherClasses = ref<number>(0);
  const expiredClasses = ref<TeacherSubjectClassDetails[]>([]);

  const onboardingVersion = ref<OnboardingVersionEnum>(OnboardingVersionEnumEnum.ONBOARDING);

  const fetchAvailableInterests = async () => {
    const interests = await OnboardingService.onboardingInterestsList({ active: true });
    availableInterests.value = interests;
  };

  const fetchUser = async () => {
    if (user.value) {
      return;
    }
    user.value = await AccountsService.accountsMeRetrieve();
  };

  const fetchTeacherClasses = async (pageSize = INITIAL_CLASSES_LIMIT) => {
    const teacherUserId = user.value?.id;
    if (!teacherUserId) {
      return;
    }

    const subjectClassesResponse = await fetchTeacherSubjectClasses(teacherUserId, false, pageSize);
    const subjectClasses = subjectClassesResponse.results ?? [];
    const subjectIds = [...new Set(subjectClasses.map(subjectClass => subjectClass.subject_id))];
    const subjectsById = await fetchSubjectsById(subjectIds);

    teacherClasses.value = subjectClasses
      .map(subjectClass => {
        const subject = subjectsById.get(subjectClass.subject_id);
        return { ...subjectClass, subject };
      })
      .filter(
        (subjectClass): subjectClass is TeacherSubjectClassDetailsWithSubject =>
          subjectClass.subject !== undefined,
      );
    totalTeacherClasses.value = subjectClassesResponse.count ?? 0;

    const response = await fetchTeacherSubjectClasses(teacherUserId, true);

    const expiredTeacherClasses = response.results?.filter(
      (subjectClass): subjectClass is TeacherSubjectClassDetails =>
        subjectClass.serializer_type === 'teacher',
    );

    expiredClasses.value = expiredTeacherClasses ?? [];
  };

  const setOnboardingVersion = (version: unknown) => {
    if (isOnboardingVersion(version)) {
      onboardingVersion.value = version;
    }
  };

  const prioritizedClass = computed(() => {
    const getSubjectClassPriority = (subjectClass: TeacherSubjectClassDetailsWithSubject) => {
      if (isIBDP(subjectClass.subject.education_system_name)) {
        return 0;
      }
      if (isIGCSE(subjectClass.subject.education_system_name)) {
        return 1;
      }
      if (isNgss(subjectClass.subject.education_system_name)) {
        return 2;
      }
      return 3;
    };
    const prioritySortedSubjectClasses = teacherClasses.value.sort(
      (a, b) => getSubjectClassPriority(a) - getSubjectClassPriority(b),
    );
    return prioritySortedSubjectClasses[0];
  });

  const isDemoUser = computed(() => user.value?.school?.is_demo);

  return {
    // State
    user,
    userRole,
    userInterests,
    freeTextInterests,
    availableInterests,
    teacherClasses,
    classSetupIssue,
    expiredClasses,
    onboardingVersion,
    totalTeacherClasses,

    // Actions
    fetchUser,
    fetchAvailableInterests,
    fetchTeacherClasses,
    setOnboardingVersion,

    // Getters
    prioritizedClass,
    isDemoUser,
  };
});

export type OnboardingStore = ReturnType<typeof useOnboardingStore>;
export default useOnboardingStore;
